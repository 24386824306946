import React from "react";
import Services from "../components/services";
import Portfolio from "../components/portfolio";
import About from "../components/about";
import Contact from "../components/contact";
import LogosFooter from "../components/logosFooter";
import Footer from "../components/footer";
import logo from '../assets/img/navbar-logo.png';
import '../css/styles.css'


function Home({acao}) {
    return(
        <div>
        <nav className={acao ? 'navbar navbar-expand-lg navbar-dark fixed-top navbar-shrink' : 'navbar navbar-expand-lg navbar-dark fixed-top'} id="mainNav">
        <div className="container">
            <a className="navbar-brand" href="#page-top"><img src={logo} alt="..." /></a>
            <a href="https://www.stone.com.br/">
                <img src="https://storage.googleapis.com/partnerhub_partner_badges_prod/D_65564c3314a0e80019a46557.png" alt="Parceiro Stone" style={{ width: '140px', height: 67 }}/>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                Menu
                <i className="fas fa-bars ms-1"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
                    <li className="nav-item"><a className="nav-link" data-target=".navbar-collapse.show" href="#services">Serviço</a></li>
                    <li className="nav-item"><a className="nav-link" href="#portfolio">Portfolio</a></li>
                    <li className="nav-item"><a className="nav-link" href="#about">Sobre</a></li>
                    <li className="nav-item"><a className="nav-link" href="#contact">Contato</a></li>
                    <li className="nav-item"><a className="nav-link" href="https://gestor.elevuslabs.app">Gestor</a></li>            
                </ul>
            </div>
        </div>
    </nav>        
    <header className="masthead">
        <div className="container">
            <div className="masthead-subheading">Bem-vindo à ElevusLabs</div>
            <div className="masthead-heading text-uppercase">Sinta-se em casa</div>
            <a className="btn btn-primary btn-xl text-uppercase" href="#services">Me diga mais...</a>
        </div>               
    </header>
    <Services/> 
    <Portfolio/>
    <About/>
    <LogosFooter/>
    <Contact/>
    <Footer/>
    </div>
    );
}

export default Home;