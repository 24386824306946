import React from "react";

import bsalesplusimg1 from '../assets/img/portfolio/bsalesplus.png';
import bsalesplusimg2 from '../assets/img/portfolio/bsalesplus2.png';
import saasImg from '../assets/img/portfolio/saas.jpg';
import rfImg from '../assets/img/portfolio/rf.png';
import canhotoImg from '../assets/img/portfolio/canhoto.png';
import bsalesImg1 from '../assets/img/portfolio/bsales.png';
import bsalesImg2 from '../assets/img/portfolio/bsales2.png';
import closeicon from '../assets/img/close-icon.svg';




class Portfolio extends React.Component{

    render(){
        return(
            <div>                
        <section className="page-section bg-light" id="portfolio">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">Portfolio</h2>
                    <h3 className="section-subheading text-muted">Confira nosso portfolio.</h3>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-sm-6 mb-4">                       
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-bs-toggle="modal" href="#portfolioModal1">
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                                </div>
                                <img className="img-fluid" src={rfImg} alt="..." />
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">RF Elevus Mobile</div>
                                <div className="portfolio-caption-subheading text-muted">Garanta a assertividade do seu estoque!</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                        
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-bs-toggle="modal" href="#portfolioModal2">
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                                </div>
                                <img className="img-fluid" src={bsalesImg1} alt="..." />
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">Elevus Business Sales</div>
                                <div className="portfolio-caption-subheading text-muted">Tenha o controle de suas vendas!</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                        
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-bs-toggle="modal" href="#portfolioModal3">
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                                </div>
                                <img className="img-fluid" src={saasImg} alt="..." />
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">SaaS</div>
                                <div className="portfolio-caption-subheading text-muted">Temos o produto certo para você!</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0">
               
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-bs-toggle="modal" href="#portfolioModal4">
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                                </div>
                                <img className="img-fluid" src={canhotoImg} alt="..." />
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">Canhoto Fiscal Elevus</div>
                                <div className="portfolio-caption-subheading text-muted">Gestão completa dos Canhotos Fiscais</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0">
               
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-bs-toggle="modal" href="#portfolioModal5">
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                                </div>
                                <img className="img-fluid" src={bsalesplusimg1} alt="..." />
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">Elevus Business Sales Plus</div>
                                <div className="portfolio-caption-subheading text-muted">Seu Força de Vendas elevando suas vendas.</div>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </section>

            <div className="portfolio-modal modal fade" id="portfolioModal1" tabindex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="close-modal" data-bs-dismiss="modal"><img src={closeicon} alt="Close modal" /></div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="modal-body">
                                  
                                    <h2 className="text-uppercase">RF Elevus Mobile</h2>
                                    <p className="item-intro text-muted">Segurança e confiabilidade na sua operação</p>
                                    <img className="img-fluid d-block mx-auto" src={rfImg} alt="..." />
                                    <p>O RF Elevus Mobile, é um sistema ágil, de fácil manuseio e bem intuitivo, trazendo tranquilidade para o usuário e garantindo de ponta a ponta que você tenha assertividade no seu estoque. 
                                        Com ele você gerante 100% que os itens que seu cliente pediu serão os itens expedidos na sua operação, evitando assim retrabalhos e devoluções desnecessárias pelo simples de fato de ir um item trocado.
                                        Tenha agilidade e segurança nos seus inventários, tudo sendo refletido em tempo real no seu Erp! A ferramenta funciona tanto em Coletores de Dados Android como em Celulares Android, trazendo liberdade 
                                        para você decidir qual tipo de aparelho escolher... Venha conhecer essa ferramenta, garanto, você não vai se arrepender! 
                                    </p>
                                    <button className="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                        <i className="fas fa-times me-1"></i>
                                        Fechar Projeto
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
        <div className="portfolio-modal modal fade" id="portfolioModal2" tabindex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="close-modal" data-bs-dismiss="modal"><img src={closeicon} alt="Close modal" /></div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="modal-body">
                                   
                                    <h2 className="text-uppercase">Elevus Business Sales</h2>
                                    <p className="item-intro text-muted">Lorem ipsum dolor sit amet consectetur.</p>
                                    <img className="img-fluid d-block mx-auto" src={bsalesImg2} alt="..." />
                                    <p>Que tal ter seu vendedor lado a lado com o cliente no chão da loja? Um atendimento personalizado onde o próprio consultor de vendas 
                                        poderá orientar o cliente qual melhor opção, caracteristicas e muito mais.. Tudo isso de forma simples, rápida e ágil. O Elevus Business Sales é uma ferramenta 
                                        genial, pensada para uso no chão de loja, ela propicia ao consultor de vendas, fazer uma venda mais personalizada, atendendo todas as demandas do cliente. 
                                        Totalmente integrada as frente de lojas 2075 e 2030 do Erp Winthor, o Business Sales traz total segurança e agilidade, tudo sendo reflatido em 
                                        tempo real no retaguarda! Além que possui a funcionalidade de consulta de preços, trazendo mais comodidade ao seu cliente! 
                                    </p>
                                    <button className="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                        <i className="fas fa-times me-1"></i>
                                        Fechar Projeto
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
        <div className="portfolio-modal modal fade" id="portfolioModal3" tabindex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="close-modal" data-bs-dismiss="modal"><img src={closeicon} alt="Close modal" /></div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="modal-body">
                                   
                                    <h2 className="text-uppercase">SaaS</h2>
                                    <p className="item-intro text-muted">A revolução no setor de software...</p>
                                    <img className="img-fluid d-block mx-auto" src={saasImg} alt="..." />
                                    <p>Saas, do inglês Software as a Service, é a oferta de software como serviço. 
                                    Contratar serviços a qualquer momento nunca foi tão fácil. E o principal responsável por isso é o tal do SaaS (Software as a Service) – Software como Serviço.
                                    Uma verdadeira revolução no modelo de consumo dos usuários e na forma com a qual as marcas disponibilizam as suas soluções. 
                                    Temos produtos direcionados ao varejo, atacado distribuidor, industria e outros segmentos! O que você precisa hoje?
                                    </p>
                                    <button className="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                        <i className="fas fa-times me-1"></i>
                                        Fechar Projeto
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="portfolio-modal modal fade" id="portfolioModal4" tabindex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="close-modal" data-bs-dismiss="modal"><img src={closeicon} alt="Close modal" /></div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="modal-body">
                                  
                                    <h2 className="text-uppercase">Canhoto Fiscal Elevus</h2>
                                    <p className="item-intro text-muted">Seus Canhotos Fiscais estarão seguros agora</p>
                                    <img className="img-fluid d-block mx-auto" src={canhotoImg} alt="..." />
                                    <p>Com essa ferramenta, você vai dizer Adeus à aquele monte de Canhotos Fiscais guardados em caixas no seu arquivo morto! Sabemos que a legislação brasileira nos obriga a manter por até 5 anos esses documentos fiacais, 
                                        desta forma existe um enorme risco de deterioração, com o Canhoto Fiscal Elevus o motorista vai digitalizar todos os canhotos no ato da entrega, e depois ele sincroniza o app com o servidor e pronto, todos os canhotos estão
                                        salvos na nuvem, para serem consultados e baixados quando o usuário desejar. Simples, rápido e eficiente.
                                    </p>
                                    <button className="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                        <i className="fas fa-times me-1"></i>
                                        Fechar Projeto
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="portfolio-modal modal fade" id="portfolioModal5" tabindex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="close-modal" data-bs-dismiss="modal"><img src={closeicon} alt="Close modal" /></div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="modal-body">
                                  
                                    <h2 className="text-uppercase">Elevus Business Sales Plus</h2>
                                    <p className="item-intro text-muted">Eleve suas vendas já!</p>
                                    <img className="img-fluid d-block mx-auto" src={bsalesplusimg2} alt="..." />
                                    <p>Agora sua empresa e sua equipe pode contar com uma ferramenta ágil, moderna e de fácil manuseio, entregando qualidade e agilidade
                                        na hora de implantar os pedidos de venda no ponto de venda! Chegou Elevus Business Sales Plus! Uma ferramenta que vai agregar segurança, rapidez,
                                        agilidade e o melhor de tudo, de forma simples e rápida! Funcionando de forma Off-Line, o vendedor será capaz de digitar os pedidos dos clientes
                                        e envia-los no melhor momento, quando houver sinal de internet! Tudo de forma rápida, simples e segura! Por que complicar se podemos simplificar!                                   
                                    </p>
                                    <button className="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                        <i className="fas fa-times me-1"></i>
                                        Fechar projeto
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="portfolio-modal modal fade" id="portfolioModal6" tabindex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="close-modal" data-bs-dismiss="modal"><img src={closeicon} alt="Close modal" /></div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="modal-body">
                                   
                                    <h2 className="text-uppercase">Project Name</h2>
                                    <p className="item-intro text-muted">Lorem ipsum dolor sit amet consectetur.</p>
                                    <img className="img-fluid d-block mx-auto" src={bsalesplusimg2} alt="..." />
                                    <p>Use this area to describe your project. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est blanditiis dolorem culpa incidunt minus dignissimos deserunt repellat aperiam quasi sunt officia expedita beatae cupiditate, maiores repudiandae, nostrum, reiciendis facere nemo!</p>
                                    <ul className="list-inline">
                                        <li>
                                            <strong>Client:</strong>
                                            Window
                                        </li>
                                        <li>
                                            <strong>Category:</strong>
                                            Photography
                                        </li>
                                    </ul>
                                    <button className="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                        <i className="fas fa-times me-1"></i>
                                        Close Project
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
            
        );
    }

}

export default Portfolio;
    