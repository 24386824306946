import React from "react";



class Footer extends React.Component {

    render() {
        return (
            <footer className="footer py-4">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 text-lg-start">Copyright &copy; ElevusLabs 2021</div>
                    <div className="col-lg-4 my-3 my-lg-0">
                        <a className="btn btn-dark btn-social mx-2" href="https://www.instagram.com/elevuslabs/"><i className="fab fa-instagram"></i></a>
                        <a className="btn btn-dark btn-social mx-2" href="https://www.facebook.com/elevuslabs"><i className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-dark btn-social mx-2" href="https://wa.me/5585996752222?text=Olá%20estou%20no%20ElevusLabs%20e%20quero%20conhecer%20melhor%20seus%20produtos."><i className="fab fa-whatsapp"></i></a>
                    </div>
                
                </div>
            </div>
            </footer>
        );
    } 
}

export default Footer;