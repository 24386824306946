import React from "react";
import emailjs from 'emailjs-com';


class Contact extends React.Component {

    state = {
        mensagemEmail: null,
        mensagemErro: null
    }

    enviarEmail =(e) => {

        e.preventDefault();

    emailjs.sendForm('GmailMessage', 'template_fjtuyre', e.target, 'user_WQgO6qL0nlj0J5L1NRerj')
      .then((result) => {
          this.setState({mensagemEmail: 'Mensagem enviada com sucesso!'});
      }, (error) => {
         this.setState({mensagemEmail: error});
      });

      e.target.reset();  // limpando os campos quando enviar o email
        
    }


    render(){

        

        return(

            <section class="page-section" id="contact">
            <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">Vamos Conversar?</h2>
                    <h3 class="section-subheading text-muted">Conte-nos mais sobre sua idéia...</h3>
                </div>
           
                <form onSubmit={this.enviarEmail} id="contactForm">
                    <div class="row align-items-stretch mb-5">
                        <div class="col-md-6">
                            <div class="form-group">
                           
                                <input class="form-control" name="name" id="name" type="text" placeholder="Diga seu Nome *" data-sb-validations="required" />
                                <div class="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
                            </div>
                            <div class="form-group">
                              
                                <input class="form-control" name="email"id="email" type="email" placeholder="E qual é seu E-mail? *" data-sb-validations="required,email" />
                                <div class="invalid-feedback" data-sb-feedback="email:required">Campo Email é obrigatório</div>
                                <div class="invalid-feedback" data-sb-feedback="email:email">Email inválido!</div>
                            </div>
                            <div class="form-group mb-md-0">
                            <input class="form-control" name="phone" id="phone" type="tel" placeholder="Telefone (99)99999-9999 *" maxLength="14" data-sb-validations="required" />
                                <div class="invalid-feedback" data-sb-feedback="phone:required">O campo telefone é obrigatório</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group form-group-textarea mb-md-0">
                            
                                <textarea class="form-control" name="message"id="message" placeholder="E agora, fale-nos mais sobre sua ideia... *" data-sb-validations="required"></textarea>
                                <div class="invalid-feedback" data-sb-feedback="message:required">A mensagem é obrigatória</div>
                            </div>
                        </div>
                    </div>


                    <div class="d-none" id="submitSuccessMessage">
                        <div class="text-center text-white mb-3">
                            <div class="fw-bolder">{this.state.mensagemEmail}</div>
                            
                        </div>
                    </div>

                    <div className="text-center text-white mb-3" id="divmsg">{this.state.mensagemEmail}</div>  

                     <div className="text-center text-white mb-3" id="divmsgErro">{this.state.mensagemErro}</div>                 
                 
                    <div class="text-center"><button class="btn btn-primary btn-xl text-uppercase" id="submitButton" type="submit">Enviar Mensagem</button></div>
                </form>
            </div>
        </section>
        );
    }

}

export default Contact;