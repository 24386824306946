import React, { useEffect, useState} from 'react';
import Home from './views/home';
import './css/styles.css'


function App() {

  const [ativaCor, setAtivaCor] = useState(false);

  useEffect(function(){
    function posicaoScroll(){
      if(window.scrollY > 8) {
        setAtivaCor(true);
      }else{
        setAtivaCor(false);
      }
    }

    window.addEventListener('scroll', posicaoScroll);
  }, []);


  return (
    <Home acao={ativaCor}/>
  );
}

export default App;
