import React from "react";
import image1 from '../assets/img/about/1.jpg';
import image2 from '../assets/img/about/2.jpg';
import image3 from '../assets/img/about/3.jpg';
import image4 from '../assets/img/about/4.jpg';
import image5 from '../assets/img/about/dev.jpg';
import image6 from '../assets/img/about/dev1.jpg';



class About extends React.Component {

    render() {
        
        return(
            <section className="page-section" id="about">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">Sobre</h2>
                    <h3 className="section-subheading text-muted">Vamos contar nossa trajetória</h3>
                </div>
                <ul className="timeline">
                    <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src={image1} alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>2017</h4>
                                <h4 className="subheading">Onde tudo começou...</h4>
                            </div>
                            <div className="timeline-body"><p className="text-muted">Após a saida do nosso CEO da então PC Sistemas que fora na época, 
                            recém adquirida pela Totvs,  sentia-se a enorme vontade de fazer mais por nossos clientes e entregar serviços e 
                            produtos com qualidade superior e o melhor, a preço justo! Assim começamos nossa trajetória... </p></div>
                        </div>
                    </li>
                    <li className="timeline-inverted">
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src={image2} alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>Janeiro 2018</h4>
                                <h4 className="subheading">Vamos aos códigos...</h4>
                            </div>
                            <div className="timeline-body"><p className="text-muted">Em Janeiro de 2018 começamos a desenvolver soluções 
                            exclusivas e direcionadas a cada um dos clientes, atendendo assim demandas específicas de cada um... Além de treinamentos.</p></div>
                        </div>
                    </li>

                    <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src={image6} alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>Agosto 2019</h4>
                                <h4 className="subheading">Nosso primeiro produto...</h4>
                            </div>
                            <div className="timeline-body"><p className="text-muted">
                                Em Agosto de 2019 lançamos na google play nosso primeiro produto, um produto inovador e com funcionalidades exclusivas frente
                                aos principais concorrentes, nasce o RF Elevus Mobile, um sistema para uso em coletores Android e celulares também, 
                                uma ferramenta que traz agilidade, segurança e assertividade no estoque de nossos clientes! Um sucesso absoluto!</p></div>
                        </div>
                    </li>

                    <li className="timeline-inverted">
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src={image5} alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>Dezembro 2020</h4>
                                <h4 className="subheading">E mais novidades...</h4>
                            </div>
                            <div className="timeline-body"><p className="text-muted">
                                E atendendo à vários pedidos de clientes, em Dezembro de 2020 lançamos mais um produto exclusivo, 
                                o Elevus Business Sales, uma ferramenta que veio para ficar! Agora os clientes podem de forma 
                                rápida e segura, inserir seus pedidos de venda no chão de loja.</p></div>
                        </div>
                    </li>



                    <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src={image3} alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>Maio 2021</h4>
                                <h4 className="subheading">Nos posicionando...</h4>
                            </div>
                            <div className="timeline-body"><p className="text-muted">Percebemos que era necessário mudar, mas nao mudar nosso DNA, 
                            mas sim mudar nossa forma de como pensamos e fazemos soluções para nossos clientes... 
                            Resolvemos então posicionar nossa marca e nosso portfolio, nasce assim a ElevusLabs, um laboratório de 
                            soluções... Web, mobile ... seja qual for sua demanda, conte-nos mais sobre ela... Tenho certeza que juntos vamos 
                            conseguir tirar seu sonho do papel e o torna-lo real!</p></div>
                        </div>
                    </li>



                    <li className="timeline-inverted">
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src={image4} alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>Outubro 2021</h4>
                                <h4 className="subheading">Lançando-se...</h4>
                            </div>
                            <div className="timeline-body"><p className="text-muted">
                                Agora é pra valer! Obrigado por ter chegado até aqui e lido um pouco da nossa história! A Elevus Consulting agora é 
                                ElevusLabs, seu laboratório criativo, de soluções móveis, web e backend!!!</p></div>
                        </div>
                    </li>
                    <li className="timeline-inverted">
                        <div className="timeline-image">
                            <h4>
                                Faça parte
                                <br />
                                da nossa
                                <br />
                                História!
                            </h4>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
        );
    }


}
export default About;