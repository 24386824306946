import React from "react";

import imgface from '../assets/img/logos/facebook.svg'
import imgmicrosoft from '../assets/img/logos/microsoft.svg'
import imggoogle from '../assets/img/logos/google.svg'
import imgimb from '../assets/img/logos/ibm.svg'





class LogosFooter extends React.Component {

    render(){
        return (
            <div className="py-5">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-3 col-sm-6 my-3">
                        <a href="https://www.microsoft.com" target="_blank" rel="noreferrer"><img className="img-fluid img-brand d-block mx-auto" src={imgmicrosoft} alt="..." /></a>
                    </div>
                    <div className="col-md-3 col-sm-6 my-3">
                        <a href="https://www.google.com" target="_blank" rel="noreferrer"><img className="img-fluid img-brand d-block mx-auto" src={imggoogle} alt="..." /></a>
                    </div>
                    <div className="col-md-3 col-sm-6 my-3">
                    <a href="https://www.stone.com.br/">
                        <img src="https://storage.googleapis.com/partnerhub_partner_badges_prod/D_65564c3314a0e80019a46557.png" alt="Parceiro Stone" style={{ width: '140px', height: 67 }}/>
                    </a>
                    </div>
                    <div className="col-md-3 col-sm-6 my-3">
                        <a href="https://www.facebook.com" target="_blank" rel="noreferrer"><img className="img-fluid img-brand d-block mx-auto" src={imgface} alt="..." /></a>
                    </div>                   
                </div>
            </div>
        </div>
        );
    }

}

export default LogosFooter;