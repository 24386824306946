import React from "react";


class Services extends React.Component {

    render() {

        return (
            <section className="page-section" id="services">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">Serviço</h2>
                    <h3 className="section-subheading text-muted">Desenvolvemos soluções úteis, criativas e de alto rendimento para nossos clientes. Apoiamos fortemente a inovação por meio de investimento em capacitação 
                    e desenvolvimento para criarmos ferramentas que tornem nossos clientes mais competitivos e mais inovadores!</h3>
                </div>
                <div className="row text-center">
                    <div className="col-md-4">
                        <span className="fa-stack fa-4x">
                            <i className="fas fa-circle fa-stack-2x text-primary"></i>
                            <i className="fas fa-mobile-alt fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 className="my-3">Apps</h4>
                        <p className="text-muted">Temos um time capacitado para desenvolver soluções hybridas ou nativas, com a mais alta tecnologia do mercado, 
                        utilizando ferramentas ágis e modernas, conectando assim os usuários Android e iOS aos seus objetivos.</p>
                    </div>
                    <div className="col-md-4">
                        <span className="fa-stack fa-4x">
                            <i className="fas fa-circle fa-stack-2x text-primary"></i>
                            <i className="fas fa-laptop fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 className="my-3">Web</h4>
                        <p className="text-muted">Projetos WEB são pensados e desenhados para atender os mais altos requisitos de segurança, experiência ao usuário, escalabilidade e flexibilidade 
                        que o seu negócio demanda. Nosso time é treinado e capacitado para construirmos plataformas de alta performace.</p>
                    </div>
                    <div className="col-md-4">
                        <span className="fa-stack fa-4x">
                            <i className="fas fa-circle fa-stack-2x text-primary"></i>
                            <i className="fas fa-cloud-upload-alt fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 className="my-3">SaaS</h4>
                        <p className="text-muted">Temos o produto ideal para cada empresa e cada segmento.</p>
                    </div>
                </div>
            </div>
        </section>
        );
    }

}

export default Services;